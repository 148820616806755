.a-button {
    text-decoration: none;
    transition: all $timing--default $ease-std;
    font-weight: $font-weight-semibold;
    font-family: $font-family-default;
    font-size: $font-size-small;
    letter-spacing: 1.2px;
    line-height: 1.1em;
    text-transform: uppercase;
    padding: 21px 32px 19px;

    &__arrow {
    }

    &--primary {
        @include attention() {
        }
    }

    &--secondary {
        @include attention() {
        }
    }

    &--default {
        @include attention() {
        }
    }

    &--outline {
        color: $color__tertiary;
        background: $color__white;
        border-color: $color__grey--light;

        @include attention() {
            border-color: $color__tertiary;
            background: $color__tertiary;
        }
    }

    &--arrow {
        @include attention() {
        }

        &::after {
        }
    }

    &--more {
        margin: 0;
    }
}
