// overwrites the default styles set at "~@bam-spark/tease/style"

.c-tease-listing {}

.a-tease {
    @include mq("sm") {
        &.utl-l-1 {}
    }
    @include mq("lg") {
        &.utl-l-1 {}

        &.utl-l-2 {}
    }

    &__link {
        @include attention() {
            .a-tease {
                &__heading {
                    color: $color__primary;
                }

                &__cta--button {
                    background: $color__primary--dark;
                }
            }
        }
    }

    &__image {}

    &__heading {
        @extend .article__content__heading;
    }

    &__text {
        color: $text-color;
    }

    &__details {
        font-size: $font-size-small;
        color: $color__primary;

        &__date {}
    }

    &__cta {
        &--button {
            @extend .a-button--primary;
        }
    }
}
@import 'tease-options/tease-default';
@import 'tease-options/tease-card';
@import 'tease-options/tease-image-block';
