.b-videoCta {
    @include mq("lg") {
        @apply flex items-center;
    }

    &__cta,
    &__video {
        @include mq("lg") {
            @apply w-1/2;
        }
    }

    &__video {
        padding-left: 1em;

        iframe {
            height: 400px;
            width: 100%;
        }
    }

    &__cta {
        @apply p-4;
        @include mq("lg") {
            @apply flex flex-col justify-center;
        }

        > *:first-child {
            margin-top: 0;
        }

        &__button {
            align-self: flex-start;
        }
    }

    &.utl-position--left {
        @include mq("lg") {
            @apply flex-row-reverse;
        }
    }
}
