// --------------------------------------------------------------------
// Tease Module
// --------------------------------------------------------------------
$tease-my: 30px;
$tease-mb: 1.5rem;
$tease-types: (
    card,
    image-block,
    default
);
$card-tease-background-colour: $color__grey--100;
$card-tease-heading-weight: $font-weight-bold;

// --------------------------------------------------------------------
// Header
// --------------------------------------------------------------------
$header-primary-height-mobile: 90px;
$header-primary-height-tablet: 110px;
$header-primary-height-desktop: 150px;
$header-primary-background-color: $color__white;
$header-secondary-background-color: $color__white;
$header-secondary-height-tablet: 40px;
$header-secondary-height-desktop: 43px;

// --------------------------------------------------------------------
// Primary Nav
// --------------------------------------------------------------------
$nav-primary-link-color: $color__text--lighter;
$nav-primary-link-color--hover: $color__grey--700;
$nav-primary-link-underline-color: $color__primary;
$nav-primary-link-underline-color--hover: $color__secondary;
$nav-primary-link-underline-color--mobile: $color__primary--alt;
$nav-primary-link-caret-color: $color__secondary;
$nav-primary-dropdown-background-color: $color__primary--dark;
$nav-primary-dropdown-link-color: $color__white;
$nav-primary-dropdown-link-color--hover: $color__white;
$nav-primary-dropdown-link-background-color--hover: $color__primary;
$nav-primary-link-spacing: 18px;

// --------------------------------------------------------------------
// Secondary Nav
// --------------------------------------------------------------------
$nav-secondary-link-color: $color__primary;
$nav-secondary-link-color--hover: $color__secondary;

// --------------------------------------------------------------------
// Mobile Nav
// --------------------------------------------------------------------
// collapse,converge,cris-cross,default,minus,scissor-alt,scissor,stack,twist
$hamburger-animations: (collapse);
$hamburger-animation-speed: 1.5;
$hamburger-color: $color__primary--alt;
$mobile-nav-color: $color__white;
$mobile-nav-background: $color__primary;
// full, default
$mobile-nav-height: (
    default
)
!default;


// --------------------------------------------------------------------
// Sidebar
// --------------------------------------------------------------------
$sidebar-heading-color: $color__grey--700;
$sidebar-heading-color--active: $color__secondary;
$sidebar-heading-color--hover: $color__primary--alt;
$sidebar-link-color: $color__primary;
$sidebar-link-color--active: $color__primary--alt ;
$sidebar-link-color--hover: $color__primary--alt;
$sidebar-bg-color: $color__grey--100;

// --------------------------------------------------------------------
// Footer Primary
// --------------------------------------------------------------------
$footer-primary-background: $color__white;
$footer-primary-text-color: $text-color;
$footer-primary-link-color: $color__secondary;
$footer-primary-link-color--hover: $color__primary--dark;

// --------------------------------------------------------------------
// Footer Secondary
// --------------------------------------------------------------------
$footer-secondary-background: $color__white;
$footer-secondary-text-color: $color__primary;
$footer-secondary-link-color: $footer-secondary-text-color;
$footer-secondary-link-color--hover: $color__secondary;

// ---------------------------------------------------------------------------
// Banners
// ---------------------------------------------------------------------------
$banner-background-colour: transparent;
$banner-text-colour: $color__white;
// Banner style (for multiple, comma separated). Use this as a variable and remove the styles
// you don't need so they won't get compiled in your css.
$banner-style: (
    slider,
    //video,
    // image
);

// ---------------------------------------------------------------------------
// Breadcrumbs
// ---------------------------------------------------------------------------
$breadcrumbs-link-color: $color__primary;
$breadcrumbs-link-color--hover: $color__secondary;
$breadcrumbs-link-color--active: $color__grey--700;
$breadcrumbs-separator-color: $color__grey--700;

// ---------------------------------------------------------------------------
// Pagination
// ---------------------------------------------------------------------------
$pagination-m: 1.5em 0;
$pagination-link-p: 23px 33px;
$pagination-link-bg: $color__grey--100;
$pagination-link-color: $color__black;
$pagination-link-bg--hover: $color__primary;
$pagination-link-color--hover: $color__white;
$pagination-link-bg--current: $color__primary;
$pagination-link-color--current: $color__white;
$pagination-link-spacing: 1px;
$pagination-alignment: (
    //left,
    center,
    //right
)
!default;
$pagination-link-transition: $transition--default;

// --------------------------------------------------------------------
// Social Icons
// --------------------------------------------------------------------
$social-icons-link-color--footer: $color__secondary;
$social-icons-link-color--navigation: $nav-secondary-link-color;
$social-icons-link-color--hover: $color__primary--alt;
