.nav--sitemap {
    @apply block my-12;

    font-size: 0.9em;

    hr {
        margin: 1em 0;
    }

    &__list {
        list-style-type: disc;
        width: 100%;

        &:not(.nav--sitemap__list--indented) {
            @include mq("sm") {
                column-gap: 1em;
                column-count: 2;
            }
            @include mq("lg") {
                column-count: 3;
            }
            @include mq("xxl") {
                column-count: 4;
            }
        }

        &__item {}

        &--indented {
            padding-left: 10px;
            list-style-type: circle;
        }
    }

    &__link {}
}
