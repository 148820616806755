.subscribe {
    @apply py-8;

     background: $color__grey--100;

    @include mq("xl") {
      @apply py-10;
    }

    &__privacy {
        @apply my-12;

        text-align: center;
        font-size: 1rem;
        color: $color__grey--700;
    }
}

.mailchimp-form {
    @apply flex my-8;

    margin: 0 auto;
    max-width: 1000px;

    @include mq($until: lg) {
        flex-direction: column;
        width: 80%;
    }

    &__input {
        @apply flex;

        width: 100%;

        @include mq($until: lg) {
            margin-bottom: 1em;
        }

        > input {
            margin-bottom: 0;
            padding: 0 1em;
            border: 1px solid $color__grey--300;

            @include mq("lg") {
              margin-right: 1em;
            }

            &::placeholder {
                font-size: 1rem;
                color: $color__grey--700;
            }
        }
    }

    &__submit {
        border: none;
        margin: 0 !important;
        justify-content: center;
        font-size: 1rem;
        color: $color__grey--700;
        font-weight: $font-weight-bold;

        @include mq($until: lg) {
            padding: 20px;
        }
    }
}
