
.b-images {
    @apply mt-8 mb-2;
    &--default {
    }

  &__image {
      &--default {
      }
  }
}


.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet::after {
    width: 25px !important;
    height: 25px !important;
}
