.child {
    @apply w-full;

    margin: 1em 0;
    min-height: 100%;
    overflow: hidden;

    @include mq("md") {
        width: 48%;
    }

    &__container {
        @apply flex flex-wrap justify-between;
        @include mq($from: md, $until: xl) {
          width: 90%;
        }
    }

    &__wrapper {
        @apply py-20;

        background: $color__grey--100;
    }

    &__image {
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__text {
        background: $color__white;
        padding: 1em;
        height: 100%;

        @include mq("md") {
          padding: 2em;
        }
    }

    &__heading {
        margin: 0;
        padding-bottom: 1em;
    }
}
