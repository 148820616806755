$color__primary: #28549B; // primary buttons
$color__primary--dark: #214480; // primary hover effects
$color__primary--alt: $color__primary--dark; // active sidebar navs
$color__secondary: #058998; // secondary buttons
$color__tertiary: #FA5300; // footer and testimonial borders

// Go make your own at https://meyerweb.com/eric/tools/color-blend/#EFEFEF:333333:6:hex
$color__grey--100: #eeeeee;
$color__grey--200: #d4d4d4;
$color__grey--300: #b9b9b9;
$color__grey--400: #9e9e9e;
$color__grey--500: #757575;
$color__grey--600: #696969;
$color__grey--700: #4E4E4E;
$color__grey--800: #333333;
$color__grey--light: #D8D8D8;
$color__grey--dark: #2A2A2A;

$color__link: $color__secondary;
$color__link--attention: $color__primary--dark;

$color__page-background: $color__grey--200;

$color__text: #2A2A2A;
$color__text--light: #404040;
$color__text--lighter: #6D7278;

$color__white: #fff;
$color__black: #000;
$color__red: #ce1f06;
$color__orange: #ee8600;
$color__yellow: #ffd404;
$color__blue: #4870B8;
$color__green: #048040;

$hamburger-color: $color__white;
$swiper__theme: $color__white;
