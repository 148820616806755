@keyframes bannerSlideIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#mask-svg {
    @apply absolute h-0 w-0;
}

.banner--slider {
    animation: bannerSlideIn 1s ease-out;
    min-height: 600px;

    &__wrapper {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 2rem;

        &::before {
            content: '';
            @apply absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba(255, 255, 255, 0) 10%, white 30%);
            z-index: 1;
        }

        &::after {
            content: '';
            @apply absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url("/assets/images/header-home-backdrop.svg");
            background-size: contain;
            background-position: center bottom;
            background-repeat: no-repeat;
            z-index: 0;
            margin-bottom: -10px;
        }

    }

    &__text {
        @include mq($until: lg) {
            top: auto;
            bottom: 15%;
            transform: none;

            &__description {
                font-size: 2em;
            }
        }

        &__container {
            @apply container relative w-full h-full;
        }
    }

    > .swiper-wrapper {
        position: absolute !important;
    }
}

.swiper-slide {
clip-path: url("#mask");

}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 1.5rem !important;
}

@include mq($until:sm) {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 5px !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 5px !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        // font-size: 2rem !important;
    }
}

@include mq($until:375px) {
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: 0 !important;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: 0 !important;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        // font-size: 1.75rem !important;
    }
}

.swiper-pagination {
    display: flex;
    align-items: center;
    position: relative !important;
    bottom: inherit !important;
    padding: 12px !important;

    @include mq("md") {
        padding: 22px 20px !important;
    }
}

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    margin: 8px;
    opacity: 0.35;
    position: relative;
    background: #3FA7B1 !important;
    transition: $transition--default;

    &:nth-child(n+1) {
        background: #28539C !important;
    }

    &:nth-child(n+2) {
        background: #008A97 !important;
    }

    &:nth-child(n+3) {
        background: #FA5300 !important;
    }

    &::after {
        content: '';
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: 2px solid transparent;
        position: absolute;
        top: -5px;
        left: -5px;
    }

    @include attention() {
        opacity: 1;

        &::after {
            border-color: $color__grey--light;
        }
    }

    &-active {
        opacity: 1;

        &::after {
            border-color: $color__grey--light;
        }
    }
}
