.article {
    max-width: 80%;

    @include mq("md") {
       max-width: 48%;
       margin-right: 1%;
    }

    @include mq("xl") {
      max-width: 30%;
      margin-right: 1%;
    }

    &__wrapper {
        @apply flex flex-wrap;
    }

    &__image {
        img {

        }

        &__link {

        }
    }

    &__content {
        background: $color__grey--100;
        padding: 1.5em 1em;
        text-align: center;

        &__heading {
            @extend .h6;

            margin: 5px 0 1em;
            font-size: 1em;
            line-height: $line-height-small;
            color: $text-color;
        }

        p {
            font-size: $font-size-small;
            margin-bottom: 10px;
        }

        &__button {
            padding: 11px 32px 9px;
            text-align: left;
            justify-content: center;

            svg {
                max-height: 30px;
                margin-right: 10px;
                margin-left: -10px;
            }
        }
    }
}
