::selection {
    color: $color__white;
    background: $color__primary;
    /* WebKit/Blink Browsers */
    text-shadow: none;
}

%visually-hidden, .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
}

%utl-reversed, .utl-reversed {
    transform: scaleX(-1);
}
