.c-featuredCta {
    @extend .container, .container--content;

    padding: 3em 0;

    @include mq("lg") {
        @apply flex;

        padding: 4em 0;
    }

    @include mq("xxl") {
        padding: 6em 0;
    }

    &__wrapper {
        @apply relative my-12;

        &::after {
            content: '';
            @apply absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url('/assets/images/featured-cta-backdrop.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 0;
        }
    }

    &__image,
    &__cta {
        @apply z-10 relative;

        @include mq("lg") {
            @apply w-1/2;
        }
    }

    &__image {
        img {
            @apply w-full h-full;

            object-fit: cover;
            object-position: center;
        }
    }

    &__cta {
        @apply text-center;

        background: $color__white;

        @include mq("lg") {
            @apply flex flex-col justify-center;
        }

        > *:first-child {
            margin-top: 0;
        }

        &__heading {
            @apply p-4;

            border-bottom: 1px solid $color__grey--light;

            h2 {
                text-transform: lowercase;
            }
        }

        &__content {
            @apply p-4;
        }

        &__bottom {
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid $color__grey--light;
        }

        &__button {
            margin: 0 0 -1px 0 !important;
            border-right: none !important;
            border-bottom: none !important;

            @include mq("md") {
              margin: -1px 0 0 0 !important;
            }

        }
    }

    &.utl-position--right {
        @include mq("lg") {
            @apply flex-row-reverse;
        }
    }
}
