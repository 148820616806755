.c-columnCta {
    @extend .container, .container--content;
    @apply my-12 mx-auto;

    @include mq("lg") {
        @apply flex;
    }

    &__image {
        height: 300px;

        @include mq("lg") {
            @apply w-1/3;

            height: auto;
        }

        img {
            @apply w-full h-full;

            object-fit: cover;
            object-position: center;
        }
    }

    &__cta {
        @include mq("lg") {
            @apply w-2/3;

            padding-left: 2em;
        }

        &__heading {
            @apply w-full;

            &:first-child {
                margin-top: 0.5em;
            }
        }

        &__content {
            .two-columns {
                @include mq($until: xl) {
                    column-count: 1 !important;
                }
            }
        }

        &__link {}
    }

    &.utl-position--right {
        @include mq("lg") {
            @apply flex-row-reverse;
        }
    }
}
