.parentHub {
    @include mq("mobile-menu") {
        .c-nav--secondary {
            &__wrapper {
                background: lighten($color__secondary, 10%);
            }

            &__list {
                &-item {
                    &-link {
                        color: $color__white;

                        @include attention() {
                           color: $color__tertiary;
                        }
                    }
                }
            }
        }
    }

    .o-content__sidebar {
        background: $color__secondary;

        .c-sidebar {
            &__heading,
            &__list-item__link {
                color: $color__white;
            }

            &::after {
                background: $color__secondary;
            }
        }
    }

    .page-header {
        color: $color__secondary;
    }
}
