form {
    @apply mx-auto;
    max-width: 800px;

    &:not(#search) {
        border: 1px solid $color__grey--200;
        padding: 1em;
        border-radius: 15px;
    }

     label{
        display: inline-block;

        &.freeform-label  {
            padding-bottom: 0.5rem;
            font-weight: $font-weight-semibold;
        }

        &:not(.freeform-label) {
            font-size: $font-size-small;
        }
    }

    .freeform-input,
    input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
    textarea,
    select {
        display: block;
        width: 100%;
        background: rgba(40, 84, 154, 0.02);
        border: 1px solid $color__grey--100;
        font-family: $font-family-default;
        color: $text-color;
        transition: 0.3s ease-in-out border-color;
        border-radius: 5px;
        font-size: $font-size-small;
        padding: 8px 12px;
        margin-bottom: 10px;

        &:focus {
            outline: none;
            border-color: $color__primary;
        }
    }

    select {
        max-height: 45px;
    }

    .input > div {
        @include mq("md") {
            display: flex;
        }
    }

    select {
        margin-right: 20px;
        height: 64px;
        border: 1px solid $color__grey--700;
        margin-bottom: 1rem;

        @include mq($until: md) {
          width: 100%;
        }

        @include mq("lg") {
            height: 67px;
        }

        @include mq("xl") {
            height: 72px;
        }
    }

    .submit {
        margin-left: -10px;
    }

    button {
        @extend %a-button;
        @extend %a-button--primary;

        transform: translateY(-3px);
    }
}

form#search {
    input[type='search'] {
        font-size: 1em;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin: 0;
    }

    button.a-button--search {
        padding: 10px 20px !important;

        @include mq("md") {
            padding: 10px 40px !important;
        }

        svg {
            margin-right: 10px;
            padding-right: 5px;
        }
    }
}

.alert, .ff-form-success {
    @apply p-4 mb-8;
}

.alert--success, .ff-form-success {
    background-color: #88C695;
}
