$sidebar-heading-color: $color__secondary !default;
$sidebar-heading-color--active: $color__secondary !default;
$sidebar-heading-color--hover: $color__primary--alt !default;
$sidebar-link-color: $color__primary !default;
$sidebar-link-color--active: $color__primary--alt  !default;
$sidebar-link-color--hover: $color__primary--alt !default;
$sidebar-bg-color: $color__grey--100 !default;

.c-sidebar {
    @apply relative z-10;
    background-color: transparent;
    padding: 2rem 1rem;

    @include mq("mobile-menu") {
      padding: 3rem 1rem 1rem;
    }

    &::after {
        content: '';
        position: absolute;
        top: 99%;
        left: 0;
        right: 0;
        height: 6em;
        background: $color__grey--100;
        z-index: -1;
    }

    &__heading {
        @apply block py-2 my-0;
        font-family: $font__secondary;
        color: $sidebar-heading-color;
        text-decoration: none;
        padding-left: 30px;

        &:matches(a) {
            @include attention() {
                color: $sidebar-heading-color--hover;
            }
        }
    }
    &__list {
        &-item {
            @apply relative flex flex-wrap justify-between items-center;
            line-height: $line-height-large;
            padding-left: 30px;
            border-bottom: 1px solid $color__grey--light;

            &__link {
                @apply block my-2;
                color: $sidebar-link-color;
                text-decoration: none;
                border-radius: 15px;
                transition: $transition--default;
                font-size: $font-size-small;

                @include attention() {
                    color: $sidebar-link-color--hover;
                }

                &.utl-is-active {
                    color: $sidebar-heading-color--active;
                    font-weight: $font-weight-bold;

                    @include attention() {
                        color: $sidebar-link-color;
                    }
                }
            }
        }

        &--descendants {
            @apply hidden w-full;

            padding-bottom: 10px;

            .c-sidebar__list-item {
                border: none;

                &__link {
                    margin: 4px 0;
                }
            }
        }
    }

    &__plus {
        @apply relative flex justify-center items-center;
        width: 30px;
        height: 30px;

        &::before {
            content: '';
            position: absolute;
            width: 10px;
            height: 2px;
            background-color: $color__grey--300;
        }

        &[aria-expanded="false"] {
            &::after {
                content: '';
                position: absolute;
                width: 2px;
                height: 10px;
                background-color: $color__grey--300;
            }
        }

        &[aria-expanded="true"] {
            + .c-sidebar__list--descendants {
                @apply block;
            }
        }

        &.utl-is-expanded {
            + .c-sidebar__list--descendants {
                animation: dropDownVisible 0.5s ease-in-out;
                animation-fill-mode: both;
            }
        }
    }
}
