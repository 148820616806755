$page-max-width: 2200px;
$container-width: 86.5%;
$container-max-width: 1640px;
$content-max-width: ( $container-max-width - 200px );
$navbar-max-width: inherit;
$header-max-width: $page-max-width;
$sidebar-width: calc(3vw + 250px);
$header-offset-mobile: $header-primary-height-mobile;
$header-offset-tablet: $header-primary-height-tablet;
$header-offset-desktop: $header-primary-height-desktop;
