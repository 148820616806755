.b-imageCta {
    @extend .container, .container--content;
    @apply relative w-full my-12;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 1em auto 6em;

    @include mq("md") {
        margin: 1em auto;
    }

    &:not(.no-bg) {
        min-height: 600px;
    }

    &__cta {
        @apply absolute;

        bottom: -3em;
        left: 1em;
        right: 1em;
        display: flex;
        flex-direction: column;
        background: $color__white;
        height: auto;

        @include mq("md") {
            &:not(.no-bg) {
                bottom: 1em;
                left: auto;
                right: 1em;
                max-width: 500px;
            }
        }

        @include mq("xl") {
            &:not(.no-bg) {
                max-width: 50%;
            }
        }

        &__content {
            padding: 1em 1em 0;

            p {
                margin-bottom: 1em;
            }
        }

        &__bottom {
            display: flex;
            justify-content: flex-end;
            border-bottom: 1px solid $color__grey--light;

            @include mq("md") {
                border-top: 1px solid $color__grey--light;
                border-bottom: none;
            }
        }

        &__button {
            margin: 0 0 -1px 0 !important;
            border-right: none !important;
            border-bottom: none !important;

            @include mq("md") {
                margin: -1px 0 0 0 !important;
            }

        }

        h2 {
            font-weight: $headings-font-weight;
            font-family: $headings-font-family;
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}
