
.banner--image {
    animation: bannerSlideIn 1s ease-out;
    height: 0 !important;
    padding-bottom: 50% !important;
    clip-path: url("#mask");

    @include mq("md") {
        padding-bottom: 30% !important;
    }
    &__wrapper {
        position: relative;
        padding-bottom: 10px;


        &::before {
            content: '';
            @apply absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba(255, 255, 255, 0) 10%, white 70%);
            z-index: 1;
        }

        &::after {
            content: '';
            @apply absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url('/assets/images/header-internal-backdrop.svg');
            background-size: 123% 100%;
            background-position: 35% 0%;
            background-repeat: no-repeat;
            z-index: 0;
            margin-bottom: -10px;
        }
    }
}
