$footer-secondary-background: #fff !default;
$footer-secondary-text-color: $color__grey--700 !default;
$footer-secondary-link-color: $color__link !default;
$footer-secondary-link-color--hover: rgba($footer-secondary-text-link-color, 0.75) !default;

.c-footer--secondary {
    @extend .container, .container--content;

    background: $footer-secondary-background;
    color: $footer-secondary-text-color;
    font-size: $font-size-small;
    padding-top: 1em;

    @include mq("sm") {
        @apply flex justify-between;
    }

    &__copyright,
    &__credit {
        margin: 10px 0;

        a {
            color: $footer-secondary-link-color;
            font-weight: $font-weight-bold;
            text-decoration: none;

            @include attention() {
                color: $footer-secondary-link-color--hover;
            }
        }
    }
}
