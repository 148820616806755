.c-sidebar--articles {
    .c-sidebar {
        &__heading {
            &--nested {
                text-decoration: none;
                padding: 0;
            }
        }

        &__list {
            &-item {
                border: none;

                &__link {
                    color: $sidebar-link-color;
                    text-decoration: none;
                    margin: 0;
                }
            }

            &--nested {
                @apply hidden w-full;
            }
        }

        &__plus {
            &[aria-expanded="true"] {
                + .c-sidebar__list--nested {
                    @apply block;
                }
            }

            &.utl-is-expanded {
                + .c-sidebar__list--nested {
                    animation: dropDownVisible 0.5s ease-in-out;
                    animation-fill-mode: both;
                }
            }
        }
    }

    .subnav-all,
    .subnav-categories,
    .subnav-archive {
        border-bottom: 1px solid $color__grey--200;
        padding: 1em 0;
        margin-bottom: 1em;
    }

    .subnav-all {}
    .subnav-categories {}

    .subnav-archive {
        .c-sidebar {
            &__heading {
                &--nested {
                    font-family: $font-family-default;
                }
            }
        }
    }
}
