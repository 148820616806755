$page-max-width: 2200px !default;
$container-width: 86.5% !default;
$container-max-width: 1640px !default;
$content-max-width: ($container-max-width - 200px ) !default;
$sidebar-width: calc(3vw + 200px) !default;
$header-max-width: 100% !default;
$navbar-max-width: $page-max-width !default;
$header-primary-height-mobile: 80px !default;
$header-primary-height-tablet: 100px !default;
$header-primary-height-desktop: 120px !default;
$header-offset-mobile: $header-primary-height !default;
$header-offset-tablet: ($header-primary-height-tablet + $header-secondary-height-tablet ) !default;
$header-offset-desktop: ($header-primary-height-desktop + $header-secondary-height-desktop ) !default;

html {
    @media screen {
        background-color: $color__page-background;
    }
}

body {
    background-color: $color__white;
    max-width: $page-max-width;
    margin: 0 auto;
}

%container,
.container {
    width: $container-width;
    max-width: $container-max-width;
    @apply mx-auto;
    @include mq("mobile-menu") {
        width: $container-width * 0.9248554913;
    }

    &--header {
        max-width: $header-max-width;
        @apply mx-auto;
    }

    &--navbar {
        @apply mx-auto;
    }

    &--content {
        max-width: $content-max-width;
    }
}

.o-page-wrap {
    position: relative;
    z-index: 1;
}

.o-header-offset {
    padding-top: $header-offset-mobile;
    @include mq($from:lg, $until:xxl) {
        padding-top: $header-offset-tablet;
    }
    @include mq("xxl") {
        padding-top: $header-offset-desktop;
    }
}

.o-content {
    &__wrapper {
        @include mq("mobile-menu") {
            @apply relative flex flex-row-reverse;
        }

        &--banner {
            @include mq("mobile-menu") {
                margin-top: -150px;
            }
        }
    }

    &__content {
        @apply pb-10 z-10;
        max-width: 100%;
        background: $color__white;
        @include mq("mobile-menu") {
            @apply flex-grow;
        }

        &__wrapper {
            @include mq("md") {
                @apply p-4;
            }
        }

        &--has-sidebar {}

        &--nobanner {
            padding-top: 3rem;
        }
    }

    &__sidebar {
        @apply block;
        background: $sidebar-bg-color;
        @include mq("mobile-menu") {
            @apply inline relative mr-8;
            min-width: $sidebar-width;
            margin-bottom: -6em;
            padding-bottom: 6em;

            &--banner {
                padding-top: 100px;
            }
        }
    }
}
