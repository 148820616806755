$footer-primary-background: $color__secondary !default;
$footer-primary-text-color: $color__grey--200 !default;
$footer-primary-text-link-color: $color__grey--200 !default;
$footer-primary-text-link-color--hover: rgba($footer-primary-text-link-color, 0.75) !default;
$footer-primary-text-link-decoration: none !default;
$footer-primary-text-link-decoration--hover: none !default;

.c-footer {
    @apply relative mt-24 pt-12;

    &::before {
        content: '';
        @apply absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 80px;
        background-image: url("/assets/images/footer-above.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--primary {
        @extend .container, .container--content;

        background: $footer-primary-background;
        color: $footer-primary-text-color;
        font-size: $font-size-small;

        @include mq("sm") {
            @apply flex;
        }

        &__logo {
            height: 120px;
            margin-bottom: 10px;

            &__image {
                object-fit: contain;
                height: 100%;
            }
        }

        &__striving {
            max-height: 40px;
            margin-bottom: 10px;

            img {
                object-fit: contain;
                height: 100%;
            }
        }

        &__list {
            @extend .list--unstyled;

            a {
                text-decoration: none;
            }
        }

        &__contact {
            min-width: 300px;

            @include mq($until: sm) {
                margin-bottom: 2em;
            }

            &__list {
                margin-bottom: 1em;

                li {
                    @apply flex justify-between w-full;

                    a {
                        width: 65%;

                    }
                }
            }

            &__link {
                margin: 1em 0;
                color: $color__tertiary;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                text-decoration: none;

                @include attention() {
                    color: $color__primary--dark;
                }
            }
        }

        &__nav {
            @apply flex flex-col flex-wrap w-full;

            @include mq("md") {
                @apply flex-row pl-4;
            }

            @include mq("lg") {
                @apply justify-center pl-0 flex-nowrap;
            }

            &__list {
                @extend .avoid-break;
                @apply w-full text-center;

                @include mq("md") {
                    @apply text-left;
                    margin-left: 5%;
                }

                li {
                    padding: 4px 0;
                }

                &--parent {
                    @include mq($from: md, $until: lg) {
                        padding-top: 1em;
                        width: 100%;
                    }
                }

                &:first-of-type {
                    @include mq("md") {
                        column-fill: balance;
                        column-count: 2;
                    }

                    @include mq("lg") {
                        column-count: 3;
                        column-gap: 2em;

                        > *:first-child {
                            margin-top: 0;
                        }
                    }
                }

                &__children,
                &__grandchildren {
                    @extend .list--unstyled, .avoid-break;

                    margin-bottom: 1em;
                }
            }

            &__link {
                color: $footer-primary-link-color;
                text-decoration: $footer-primary-text-link-decoration;

                @include attention() {
                    color: $footer-primary-link-color--hover;
                }
            }

            &__parent {
                > a {
                    color: $footer-primary-link-color--hover;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    text-decoration: $footer-primary-text-link-decoration;

                    @include attention() {
                        color: $color__tertiary;
                    }
                }
            }
        }
    }
}
