$social-icons-link-color--footer: $color__black !default;
$social-icons-link-color--navigation: $color__black !default;
$social-icons-link-color--hover: $color__primary--alt !default;

.c-socialIcons {
    display: flex;
    align-self: center;
    max-width: 180px;

    > * + * {
        margin-left: 0.5rem;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    &.c-socialIcons--footer {
        @apply my-4;

        .c-socialIcons__link {
            color: $social-icons-link-color--footer;

            @include attention() {
                color: $social-icons-link-color--hover;
            }
        }
    }

    &.c-socialIcons--navigation {
        .c-socialIcons__link {
            color: $color__grey--700;

            @include mq("mobile-menu") {
                color: $social-icons-link-color--navigation;
            }

            @include attention() {
                color: $social-icons-link-color--hover;
            }
        }
    }
}
