.c-breadcrumbs {
    margin: 0;
    display: block;
    padding: 12px 1rem;
    border-bottom: 1px solid $color__grey--100;
    font-size: $font-size-small;
    text-transform: uppercase;
    letter-spacing: 1.2px;

    > ul {
        margin-left: 0 !important;
    }

    &__item {
        + .c-breadcrumbs__item {
            &::before {}
        }
    }

    &__link {
        color: $breadcrumbs-link-color;
        text-decoration: none;

        @include attention() {
           color: $breadcrumbs-link-color--hover;
        }

        &.utl-is-active {
            color: $breadcrumbs-link-color--active;
        }
    }
}
