$nav-primary-link-color: $color__grey--700 !default;
$nav-primary-link-color--hover: $color__primary--alt !default;
$nav-primary-link-underline-color: $color__primary !default;
$nav-primary-link-underline-color--hover: $color__grey--700 !default;
$nav-primary-link-underline-color--mobile: $color__primary--alt !default;
$nav-primary-link-caret-color: $color__primary !default;
$nav-primary-dropdown-background-color: $color__grey !default;
$nav-primary-dropdown-link-color: $color__secondary !default;
$nav-primary-dropdown-link-color--hover: $color__white !default;
$nav-primary-dropdown-link-background-color--hover: $color__primary !default;
$nav-primary-link-spacing: 18px !default;

.c-nav--primary {
    @apply w-full self-center;

    &__wrapper {
        @apply flex flex-grow flex-wrap;
        border-top: 1px solid $color__grey--light;
        @include mq("xl") {
            @apply pr-6;
        }
        @include mq("xxxl") {
            @apply pr-24;
        }
    }

    &__list {
        @apply flex justify-end list-none;

        &-item {
            @apply relative flex;

            padding: 0 5px;

            @include mq("xl") {
                padding: 0 $nav-primary-link-spacing;
            }

            &-link {
                @apply relative self-center;
                color: $nav-primary-link-color;
                font-family: $font-family-default;
                font-weight: $font-weight-semibold;
                font-size: 0.7em;
                letter-spacing: 2.5px;
                line-height: 1.1em;
                text-transform: uppercase;
                padding: 15px ($nav-primary-link-spacing / 2);
                text-decoration: none;

                &--search {
                    @apply flex items-center;
                    margin-top: -0.25rem;
                }

                &-caret {
                    @apply hidden self-center ml-1;
                    height: 0;
                    margin-top: -28px;
                    color: $nav-primary-link-caret-color;
                    @include mq("mobile-menu") {
                        @apply inline;
                    }
                }
                @include attention() {
                    @include mq("mobile-menu") {
                        color: $nav-primary-link-color--hover;
                    }
                }

                &.utl-is-active {
                    @include attention() {}
                }
            }
        }
    }

    &__dropdown-menu {
        @apply hidden absolute list-none;
        @include mq("mobile-menu") {
            margin-top: -2px;
            background: $nav-primary-dropdown-background-color;
            top: 100%;
            min-width: 250px;
            transition: all 0.3s ease;
            margin-left: 26px;
        }

        &-item {
            @apply block;

            &-link {
                text-decoration: none;
                font-size: 0.9em;

                @include mq("mobile-menu") {
                    @apply block;
                    color: $nav-primary-dropdown-link-color;
                    padding: 15px;
                    transition: all 0.15s ease-in-out;
                    line-height: 1.3;
                }
                @include attention() {
                    @include mq("mobile-menu") {
                        color: $nav-primary-dropdown-link-color--hover;
                        background-color: $nav-primary-dropdown-link-background-color--hover;
                    }
                }

                &.utl-is-active {
                    @include mq("mobile-menu") {
                        background-color: $nav-primary-dropdown-link-background-color--hover;
                        color: $nav-primary-dropdown-link-color--hover;
                    }
                }
            }
        }
    }
}

.navbar--centre {
    @include mq("mobile-menu") {
        @apply flex-col items-center;

        .c-nav--primary {
            &__wrapper {
                @apply w-full;
            }

            &__list {
                @apply justify-center;
            }
        }
    }
}
@keyframes dropDownVisible {
    0% {
        left: -4px;
        opacity: 0;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

.has-dropdown {
    @include mq("mobile-menu") {
        @include attention() {
            > .c-nav--primary__dropdown-menu {
                display: block;
                animation: dropDownVisible 0.2s ease-in;
                animation-fill-mode: both;
            }

            .c-nav--primary__list-item-link {
                color: $nav-primary-link-color--hover;

                &::after {
                    width: 0;
                    background-color: transparent;
                }
            }
        }

        &:focus-within {
            .c-nav--primary__dropdown-menu {
                display: block;
                animation: dropDownVisible 0.2s ease-in;
                animation-fill-mode: both;
            }
        }
    }
}
