.c-latestNews,
.c-upcomingEvents {
    @apply flex flex-wrap;

    @include mq($until: md) {
        justify-content: center;
    }

    &__section {
        @extend .container, .container--content;
        @apply my-12;
    }

    &__header {
        @apply flex justify-between items-end my-4;

        &__link,
        &__title {
            @apply m-0;
        }

        &__link {
            color: $color__secondary;
            text-decoration: none;
            transition: all $timing--default $ease-std;
            font-size: $font-size-small;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }
    }

    &__article {
        @apply mb-6;

        @include mq("md") {
            flex-basis: 33%;

            &:not(:first-of-type) {
                padding-left: 1em;
            }
        }

        &__image {
            img {
                max-height: 200px;
                height: 100%;
                width: auto;
                object-position: center;
                @include mq($until: sm) {
                    width: 100%;
                }
            }
        }

        &__content {
            &__title {
                font-weight: $font-weight-semibold;
                margin: 1em 0 10px;
            }

            p {
                margin-bottom: 10px;
            }

            &__date {
                color: $color__grey--500;
                font-size: $font-size-small;
                text-transform: uppercase;
                letter-spacing: 1.2px;
                margin-bottom: 1em;
            }

            &__categories {}

            &__link {
                color: $color__tertiary;
                text-decoration: none;
                transition: all $timing--default $ease-std;
                font-size: $font-size-small;
                font-weight: $font-weight-semibold;
                text-transform: uppercase;
                letter-spacing: 1.2px;
            }
        }
    }
}
