$nav-primary-link-color: $color__white !default;
$nav-primary-link-color--hover: $color__grey--200 !default;

.c-nav--secondary {
    @apply hidden pr-2;
    @include mq("mobile-menu") {
        font-size: 0.9rem;
        @apply inline-block w-full items-center;
    }

    &__wrapper {
        @apply flex flex-grow flex-wrap;
        @include mq("xl") {
            @apply pr-12;
        }
        @include mq("xxxl") {
            @apply pr-24;
        }
    }

    &__list {
        @apply flex justify-end list-none;

        &-item {
            @apply relative inline-flex;
            padding: 0 ($nav-primary-link-spacing / 3);

            &-link {
                @apply relative self-center;
                color: $nav-primary-link-color;
                font-family: $font-family-default;
                font-size: 0.7em;
                font-weight: $font-weight-semibold;
                letter-spacing: 2px;
                line-height: 1.1em;
                text-transform: uppercase;
                padding: 7px ($nav-primary-link-spacing / 2) 4px;
                text-decoration: none;
                @include attention() {
                    color: $nav-primary-link-color--hover;
                }

                &--parenthub {
                    color: $color__tertiary;
                    @include attention() {
                        color: $color__primary--dark;
                    }
                }

                &--search {
                    @apply flex items-center;
                    color: $color__primary--dark;
                    @include attention() {
                        color: $color__secondary;
                    }
                }
            }
        }
    }

    &__dropdown-menu {
        @apply hidden absolute list-none;
        // @include mq("mobile-menu") {
        //     margin-top: -2px;
        //     background: $color__secondary;
        //     top: 100%;
        //     min-width: 250px;
        //     transition: all 0.3s ease;
        //     left: 0;
        // }
        //
        // &-item {
        //     @apply block;
        //
        //     &:first-of-type {
        //         &-link {
        //             border-top-left-radius: 10px;
        //             border-top-right-radius: 10px;
        //         }
        //     }
        //
        //     &:last-of-type {
        //         &-link {
        //             border-bottom-left-radius: 10px;
        //             border-bottom-right-radius: 10px;
        //         }
        //     }
        //
        //     &-link {
        //         text-decoration: none;
        //         font-size: $font-size-small;
        //
        //         @include mq("mobile-menu") {
        //             @apply block z-10;
        //             color: $nav-primary-dropdown-link-color;
        //             padding: 8px 15px;
        //             transition: all 0.15s ease-in-out;
        //             line-height: 1.3;
        //         }
        //         @include attention() {
        //             @include mq("mobile-menu") {
        //                 color: $color__white;
        //                 background-color: $nav-primary-dropdown-link-background-color--hover;
        //             }
        //         }
        //
        //         &.utl-is-active {
        //             @include mq("mobile-menu") {
        //                 background-color: $nav-primary-dropdown-link-background-color--hover;
        //                 color: $nav-primary-dropdown-link-color--hover;
        //             }
        //         }
        //     }
        // }
    }
}

// .has-dropdown {
//     @include mq("mobile-menu") {
//         @include attention() {
//             > .c-nav--secondary__dropdown-menu {
//                 display: block;
//                 animation: dropDownVisible 0.2s ease-in;
//                 animation-fill-mode: both;
//             }
//
//             .c-nav--secondary__list-item-link {
//                 color: $nav-primary-link-color--hover;
//
//                 &::after {
//                     width: 0;
//                     background-color: transparent;
//                 }
//             }
//         }
//
//         &:focus-within {
//             .c-nav--secondary__dropdown-menu {
//                 display: block;
//                 animation: dropDownVisible 0.2s ease-in;
//                 animation-fill-mode: both;
//             }
//         }
//     }
// }
