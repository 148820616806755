.banner {
    @apply mb-4 relative;
    z-index: 2;

    &--standard {
        max-height: inherit !important;
        height: 0 !important;
        min-height: inherit !important;
        padding-bottom: 35% !important;
    }

    &--large {
        max-height: inherit !important;
        height: 0 !important;
        padding-bottom: 50% !important;

        @include mq("lg") {
            min-height: inherit !important;
        }
    }

    &__text {
        background: $color__white;
        max-width: 600px;
        right: 0;
        font-size: 1em;

        @include mq("xl") {
            max-width: 800px;
        }

        @include mq("xxl") {
            max-width: 1000px;
        }

        &__heading {
            @extend h6;
            @apply mt-6 mb-2 ml-6;
            color: $color__text--lighter;
            text-shadow: none;
        }

        &__description {
            @extend h1;
            @apply mt-2 mb-4 ml-6;
            color: $color__text--light;
            text-shadow: none;
        }
    }

    &__image {

        @include mq($until: lg) {
            > picture {

                > img {
                }
            }
        }
    }

    &__links {
        @apply flex justify-between mx-0;

        border-top: 1px solid $color__grey--light;

        > .a-button {
            @extend .a-button--outline;
            @apply mb-0;
            margin-top: -1px;
        }
    }
}


@import 'banner-options/banner--slider';
@import 'banner-options/banner--image';
