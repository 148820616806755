// overwrites the default styles set at "~@bam-spark/entries/style"

.b-entries {
    @apply mt-8 mb-2 text-center;

    &__wrapper--outer {
        @apply text-center;
    }

    &__wrapper {}

    &__image-wrapper {
        @extend %box-shadow;

        @include attention() {
            @extend %box-shadow--lg;
        }
    }

    &__heading {}
}
