.b-imageText {
    @apply mt-8 mb-2;

    &--standard {
        > .b-imageText__image {
            max-height: 300px;
            overflow: hidden;

            img {
                object-fit: cover;
            }

            @include mq("lg") {
              max-height: 600px;

              img {
                  object-fit: contain;
              }
            }
        }
    }
    &--large {
        @apply my-12;

        > .b-imageText__image {
            img {
                object-fit: cover;
            }
        }
    }

    &__image {
        @include mq($from:xl, $until:xxl) {
            @apply flex;
        }

        > picture {
            @include mq($from:xl, $until:xxl) {
                @apply flex justify-center overflow-hidden;
            }

            > img {
                @apply w-full;

                @include mq($from:xl, $until:xxl) {
                    max-width: initial;
                }
            }
        }
    }

    &__text {
        @apply py-8 pl-0;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        &.utl-has-background-color {
            @apply pl-8;

            @include mq("xl") {
                @apply pl-12;
            }
        }
    }

    &.utl-position--left {
        > .b-imageText__text {
            @apply pl-8;

            @include mq("xl") {
                @apply pl-12;
            }
        }
    }

    &.utl-position--right {
        @include mq("lg") {
            @apply flex-row-reverse;
        }

        > .b-imageText__text {
            @apply pr-8;

            @include mq("xl") {
                @apply pr-12;
            }
        }
    }
}
