.a-logo {
    &__wrapper {
        @apply flex h-full content-center px-8;
        border-right: 1px solid $color__grey--light;

        @include mq("xl") {
            @apply px-16;
        }
    }

    &__link {
        @apply flex items-center w-full;
        height: $header-primary-height-mobile;

        @include mq($from:lg, $until:xxl) {
            height: $header-primary-height-tablet;
        }
        @include mq("xxl") {
            height: $header-primary-height-desktop;
        }
    }

    &__image {
        height: 100%;
        min-width: 100px;
        object-fit: contain;
        transition: $transition--default;

        @include mq("mobile-menu") {
            min-width: 200px;
        }
    }
}
