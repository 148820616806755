$font-family-default: apertura, sans-serif;
$font__secondary: questa-grande, serif;
$font__tertiary: $font-family-default;

$text-color: $color__grey--dark;
$headings-color: $color__primary--alt;

$font-weight-ultralight: 400 !default;
$font-weight-light: 400 !default;
$font-weight-regular: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 700 !default;

$line-height-xsmall: 1.2;
$line-height-small: 1.4;
$line-height-default: 1.6;
$line-height-large: 1.8;
$line-height-xlarge: 2;

$headings-font-family: $font__secondary;
$headings-font-weight: $font-weight-regular !default;
$headings-line-height: $line-height-small !default;

// Base font size
$font-size-default: 20px;

$font-size-xsmall: 0.6em;
$font-size-small: 0.8em;
$font-size-large: 1.2em;
$font-size-xlarge: 1.5em;

// Heading sizes - https://www.modularscale.com/?1&em&1.175
$font-size-h1: 3em;
$font-size-h2: 2em;
$font-size-h3: 1.8em;
$font-size-h4: 1.5em;
$font-size-h5: 1.2em;
$font-size-h6: 0.8em;

// Banner headings
$font-size-banner-heading-min: 60px;
$font-size-banner-heading-max: 70px;

// eg p.lead
$font-size-large-min: 14px;
$font-size-large-max: 18px;

$paragraph-spacing: 2rem;
